import React from "react"
import styled from "@emotion/styled"

import Layout from "../components/Layout"
import logomarkStatic from "../images/logomark_simple.svg"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {faLinkedinIn, faGithub, faTwitter, faBehance} from "@fortawesome/free-brands-svg-icons"

const PageContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    margin-top: 8vh;

    @media (max-width: 420px) {
      flex-direction: column-reverse;
    }
`;

const ContentColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    width: 60%;

    @media (max-width: 420px) {
      width: 100%;
    }
`;

const Bodytext = styled.div`
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 1em;

    width: 100%;

    letter-spacing: 0.04em;
    line-height: 1.6em;
    color: #000;

    @media max-width(420px){
      width: 100%;
    }
`;

const BodytextParagraph = styled.p`
    margin-bottom: 1em;
`;

const ImageColumn = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    width: 40%;
    height: 80vh;

    background: url();
    background-position: center;
    background-image: cover;
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 420px) {
      width: 100%;
      height: 40vh;
    }
`;

const Subtitle = styled.h2`
    font-family: 'Poppins';
    font-weight: 500;
    font-size: 1.5em;
    
    letter-spacing: 1.8px;
    color: #ff6666;

    font-style: normal;
    font-stretch: normal;
    line-height: normal;

    margin: 0;

    text-transform: uppercase;

    @media (max-width: 420px){
        font-size: 1.3em;
    }
`;

const Title = styled.h1`
    font-family: 'Poppins';
    font-weight: 500;

    color: #003399;

    font-size: 2.7em;
    letter-spacing: 1px;

    margin: 8vh 0 4vh 0;

    @media (max-width: 420px){
        font-size: 2.4em;
        margin: 48px 0 24px 0;
    }
`;

const ContactLinks = styled.a`
    font-family: 'Roboto';
    font-weight: 600;

    text-decoration: none;

    color: #003399;
`;

const SubSectionTitle = styled(Subtitle)`
    font-size: 1.2em;
    margin-top: 2em;
    text-transform: lowercase;

    &first-child {
      margin-top: 0em;
    }
`;

const BodySection = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    max-width: 100%;
    max-height: 100%;

    margin: 1em 0em;
`;

const CvTile = styled.a`
    font-family: 'Poppins';
    font-weight: 500;
    text-align: center;
    
    font-size: 1em;
    text-decoration: none;
    color: #003399;
    
    max-height: 100%;
    width: 48%;
    height: 100%;

    padding: 1em 0em;
    
    background-color: #CDDEFF;
`;

const ProfileTile = styled(CvTile)`
    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: rgba(0,0,0,0);

    font-size: 2em;
    text-decoration: none;
`;

const ProfileLabel = styled.div`
    font-size: .3em;
    text-align: center;
    text-decoration: none;
`;

export default () => (
  <Layout>
    <PageContent>
      <ContentColumn>
        <Subtitle>
          Drop a line 
        </Subtitle>
        <Title>
           Come say hello!
        </Title>
        <Bodytext>
            <BodytextParagraph>
            Interested in working together on a project? 
            </BodytextParagraph>
            <BodytextParagraph>
            Easiest way to get in touch is through <ContactLinks href="https://www.linkedin.com/in/jamie-brannan/" target="_blank">LinkedIn</ContactLinks> or <ContactLinks href="mailto:jamiebrannan2@gmail.com">email</ContactLinks>.
            </BodytextParagraph>
          <br/><br/>
          <SubSectionTitle>
              Direct CV Download
          </SubSectionTitle>
          <BodySection>
              <CvTile href="../../BRANNAN_Jamie_CV__developer_eng.pdf">English</CvTile>
              <CvTile href="../../BRANNAN_Jamie_CV__developer_fr.pdf">French</CvTile>
          </BodySection>
          <SubSectionTitle>
              social profiles
          </SubSectionTitle>
          <BodySection>
              <ProfileTile href="https://www.linkedin.com/in/jamie-brannan/" target="_blank">
              <FontAwesomeIcon icon={faLinkedinIn} color="$primary-blue" />
                <ProfileLabel>
                    LinkedIn
                </ProfileLabel>
              </ProfileTile>
              <ProfileTile href="https://github.com/jamie-brannan" target="_blank">
              <FontAwesomeIcon icon={faGithub} color="$primary-blue" />
                  <ProfileLabel>
                    Github
                  </ProfileLabel>
              </ProfileTile>
              <ProfileTile href="https://twitter.com/jamiembrannan" target="_blank">
                <FontAwesomeIcon icon={faTwitter} color="$primary-blue" />
                <ProfileLabel>
                    Twitter
                </ProfileLabel>
              </ProfileTile>
              <ProfileTile href="https://www.behance.net/jamiebrannan" target="_blank">
              <FontAwesomeIcon icon={faBehance} color="$primary-blue" />
                  <ProfileLabel>
                    Bēhance
                  </ProfileLabel>
              </ProfileTile>
          </BodySection>
        </Bodytext>
      </ContentColumn>
      <ImageColumn>
        <img width="80%" src={logomarkStatic}/>
      </ImageColumn>
    </PageContent>
  </Layout>
)